import { buyout } from 'js-mp';
import extend from 'lodash/extend';
import router from '@/router';

const config_overrides = {
	currency: '€',
	language: 'sk',
	router: router,
	isURLHandlingEnabled: true,
  international_phone_prefix: '+421',
  swap_first_country: true,
  default_bank_code: '0200',
  minimal_buyout_price: 0.5,
};

export const selectable_xbox_models = ['Xbox Series S', 'Xbox Series X'];
export const eds_licence_type = 'microsoft';
export const esd_product_id = '811-7-02032';

export const allowed_date_formats = [
	'DD.MM.YYYY',
	'DD.MM.YY',
	'DD. MM. YYYY',
	'DD. MM. YY',
	'YYYY-MM-DD'
];
buyout.config = extend(buyout.config, config_overrides);
export const config = buyout.config;
