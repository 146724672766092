import { allowed_date_formats } from './config';

export const overrides = {
  sk: {
    buyout: {
      AdditionalInfoForm: {
				new_selected_model: 'Model nového Xboxu',
				select_new_model_name: 'Vyber model svojho novo zakúpeného Xboxu',
				new_model_partner_sub_text:
					'Vyber predajcu u ktorého bol nový Xbox zakúpený'
			},
      deviceFinder: {
        chose_type: 'Vyber typ',
        chose_brand: 'Vyber značku',
        chose_model: 'Vyber model',
        give_registration_number: 'Zadaj číslo tvojej registrácie',
      },
      contractForm: {
        bank_account_full_number: 'Číslo bankovného účtu (IBAN)',
        chose_salutation: 'Vyber oslovenie',
        chose_country: 'Vyber štát',
      },
      give_registration_number: 'Zadaj číslo tvojej registrácie',
      send_info_email_label:
        'Zadaj prosím tvoj e-mail, na ktorý zašleme informatívne nacenenie zariadenia.',
      thankYou: {
        preSale: {
          title:
            'Informatívne nacenenie bolo odoslané na tvoju e-mailovú adresu pod číslom <b>{0}</b>.',
          text:
            'Pre dokončenie výkupu zariadenia sa zastav na niektorej z našich predajní.',
          text_w_link:
            'Pre dokončenie výkupu zariadenia sa zastav na niektorej z našich <a href="{0}">predajní</a>.'
        },
      },
      error: {
        buyout_not_found: 'Výkup nebol nájdený. Skontroluj číslo výkupu.',
      },
      bonus: {
        select_device_for_bonus: 'Vyber tvoje nové zariadenie',
        select_your_new_device: 'Vyber tvoj nový {0}',
        select_seller_of_your_new_device: 'Vyber predajcu tvojho nového {0}'
      },
      partners: {
        select_province: 'Vyber kraj',
        for_display_select_province:
          'Pre zobrazenie zoznamu partnerov prosím vyber kraj',
      },
    },
		validators: {
			is_date_valid:
				'Nesprávny formát dátumu. Použite prosím jeden z týchto formátov: ' +
				allowed_date_formats.join(', '),
      invalid_bank_account_number: 'Nesprávny IBAN.',
		},
    console: {
      choose_console_model_accessories_or_game: 'Vyber model konzoly, príslušenstvía alebo hry',


      thank_you: 'Ďakujeme, viac informácií dostaneš mailom',
      where_you_can_buy_new_console: 'U ktorých môžeš zakúpiť konzole a príslušenstvo značky Xbox',
      take_opportunity_to_contact_a_professional_purchase_assistant:
        'Využi možnosť kontaktovania profesionálnym asistentom výkupu',
      xbox_membership_text:
        'Členstvo EA Play je teraz zahrnuté v predplatnom Xbox Game Pass for PC a Ultimate, a to bez ďalšieho\n' +
        '            príplatku. S EA Play získaš prístup ku kolekcii najobľúbenejších hier od EA, odmenám v hrách a skúšobným\n' +
        '            verziám vybraných nových hier.',
      join_now: 'Pridaj sa ihneď',
    }
	},
};
